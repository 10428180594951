import React from "react";
import "./App.css";
import { biopaySky, biopayNavy, biopayIcon } from "./assets";

const App: React.FC = () => {
  return (
    <main className="p-4 lg:p-7 xl:p-14">
      <header className="flex justify-end w-full">
        <img src={biopaySky} alt="biopay sky logo" className="h-[2.3rem]" />
      </header>

      <section className="flex items-center justify-center h-[90vh] pb-40 lg:pb-0 gap-0 lg:gap-7">
        <div className="w-5/12 lg:w-3/12 flex flex-col items-center lg:items-end justify-end h-[70vh]">
          <div className="bg-[#03435F] w-[70%] lg:w-[64%] max-w-[200px] h-[36vh] lg:h-[60vh] rounded-b-lg rounded-t-[99px] flex flex-col items-center justify-end pb-14">
            <img
              src={biopayIcon}
              alt="biopay icon"
              className="h-12 lg:h-20 xl:h-24"
            />
          </div>
        </div>

        <div className="w-7/12 lg:w-9/12 flex flex-col items-start justify-end h-[70vh] pb-4">
          <h4 className="pl-2 text-[#0B7FB1] lg:text-2xl">Introducing</h4>

          <div>
            <img src={biopayNavy} alt="biopay logo" className="h-18 lg:h-24" />
          </div>
        </div>
      </section>
    </main>
  );
};

export default App;
